import { provideHttpClient, withInterceptors } from '@angular/common/http';
import { ErrorHandler } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import { provideAuth0 } from '@auth0/auth0-angular';
import { provideFeatureFlags } from '@bannerflow/feature-flags';
import { provideNotificationService } from '@bannerflow/notification';
import { provideSentinelService, withNewRelic } from '@bannerflow/sentinel';
import { AppConfig } from '@config/app.config';
import { SignalRHubService } from '@core/services/api/signalr/signalr-hub.service';
import { TestSignalRHubService } from '@core/services/api/signalr/test-signalr-hub.service';
import { CookieService } from '@core/services/internal/cookie.service';
import { GlobalErrorHandler } from '@plugins/global-error-handler.service';
import { NewRelicPlugin } from '@plugins/new-relic.plugin';
import { getAuthConfig } from '@shared/auth0/auth0.config';
import { UserSessionGuard } from '@shared/guards/user-session.guard';
import { proxyAuthInterceptorFn } from '@shared/interceptors/proxy-auth.interceptor';
import { EllipsisPipe } from '@shared/pipes/ellipsis.pipe';
import { BehaviorSubject } from 'rxjs';
import { config as environment } from '../lib/config/environment.dev';
import { routes } from './app.routes';
import { AppService } from './core/services/internal/app.service';
import { DatePipe } from '@angular/common';

export const appConfig = {
    providers: [
        AppService,
        provideRouter(routes),
        provideAnimations(),
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        {
            provide: SignalRHubService,
            useClass: CookieService.get('IN_TEST') ? TestSignalRHubService : SignalRHubService
        },
        UserSessionGuard,
        provideSentinelService(
            withNewRelic({
                enabled: AppConfig.config.STAGE !== 'local',
                ...NewRelicPlugin.init(AppConfig.config.STAGE === 'production', [
                    AppConfig.config.origins.LIST_SERVICE_URL,
                    AppConfig.config.origins.STUDIO_URL
                ])
            })
        ),
        provideNotificationService({
            apiUri: AppConfig.config.origins.NOTIFICATION_SERVICE_URL,
            accessToken$: new BehaviorSubject<string | undefined>(undefined),
            signalR: { enabled: AppConfig.config.NSSignalR.enabled, url: AppConfig.config.NSSignalR.url }
        }),
        provideHttpClient(withInterceptors([proxyAuthInterceptorFn])),
        provideAuth0(getAuthConfig()),
        provideFeatureFlags({
            enabled: environment.FEATURE_FLAGS.enabled,
            appName: 'BannerflowClient',
            url: environment.FEATURE_FLAGS.url,
            clientKey: environment.FEATURE_FLAGS.clientKey
        }),
        EllipsisPipe,
        DatePipe
    ]
};
