import { CommonModule } from '@angular/common';
import { Component, effect, inject, ViewContainerRef } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RouterOutlet } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';
import { NS_CONFIG_TOKEN } from '@bannerflow/notification';
import { UIModule, UIThemeService } from '@bannerflow/ui';
import { AppService } from '@core/services/internal/app.service';

@Component({
    selector: 'app-root',
    imports: [CommonModule, UIModule, RouterOutlet],
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss']
})
export class AppComponent {
    private authService = inject(AuthService);
    private nsConfig = inject(NS_CONFIG_TOKEN);
    private user = toSignal(this.authService.user$);

    constructor(
        public appService: AppService,
        public viewContainerRef: ViewContainerRef,
        private themeService: UIThemeService // Do not remove since the constructor needs to run to set default theme
    ) {
        effect(() => {
            const user = this.user();
            if (user) {
                this.authService.getAccessTokenSilently({ cacheMode: 'on' }).subscribe(accessToken => {
                    this.nsConfig.accessToken$.next(accessToken);
                });
            }
        });
    }
}
