import { IAppConfig } from './app.config.type';

export const config: IAppConfig = {
    BUILD_VERSION: '99cf61371504f2937ab0b87882b14fbb4d8c8e0c',
    STAGE: 'sandbox',
    origins: {
        APP_URL: 'https://sandbox-home.bannerflow.com',
        B2_URL: 'https://sandbox-app.bannerflow.com',
        STUDIO_URL: 'https://sandbox-studio.bannerflow.com',
        SAPI_URL: 'https://sandbox-api.bannerflow.com/studio',
        CAMPAIGN_SERVICE_URL: 'https://sandbox-api.bannerflow.com/cs',
        PUBLISH_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ps',
        MIGRATION_SERVICE_URL: 'https://sandbox-migrationservice.bannerflow.com',
        ANALYTICS_BACKEND_SERVICE_URL: 'https://sandbox-api.bannerflow.com/ab',
        CAMPAIGN_MANAGER_URL: 'https://sandbox-cm.bannerflow.com',
        AD_SERVICE_URL: 'https://c.sandbox-bannerflow.net',
        SOCIAL_CAMPAIGN_SERVICE_URL: 'https://sandbox-scs.bannerflow.com',
        SOCIAL_CAMPAIGN_MANAGER_URL: 'https://sandbox-scm.bannerflow.com',
        SOCIAL_ACCOUNT_SERVICE_URL: 'https://sandbox-sas.bannerflow.com',
        CUSTOM_HOSTNAMES_SERVICE_URL: 'https://sandbox-chs.bannerflow.com',
        SCHEDULE_MIGRATION_CLIENT_URL: 'https://sandbox-smc.bannerflow.com',
        BAU_URL: 'https://sandbox-api.bannerflow.com/bau',
        LIST_SERVICE_URL: 'https://sandbox-api.bannerflow.com/list-service',
        ACCOUNT_ACCESS_URL: 'https://sandbox-api.bannerflow.com/account-access',
        ANALYTICS_FRONTEND_URL: 'https://sandbox-analytics.bannerflow.com',
        FEEDS_SUPREME_COMMANDER: 'https://sandbox-api.bannerflow.com/feeds-supreme-commander',
        FEEDS_CLIENT: 'https://sandbox-feeds.bannerflow.com',
        CREATIVE_PREVIEW_URL: 'https://sandbox-api.bannerflow.com/preview',
        COMMENTS_URL: 'https://sandbox-api.bannerflow.com/comment-service',
        NOTIFICATION_SERVICE_URL: 'https://sandbox-api.bannerflow.com/notification-service/api',
        CUSTOM_HOSTNAME_URL: 'https://sandbox-chs.bannerflow.com'
    },
    GTM_ID: 'GTM-M2FZ5ZZ',
    AZURE_APP_CONFIG_FEATURES_CONNECTION_STRING:
        'Endpoint=https://bf-shared-sandbox-ac.azconfig.io;Id=+d6H-l8-s0:ZDsMi4u4B3bL3Y9VAexD;Secret=kycnl5jmUghCUDu70c/LdKSinLqeEtkZE08EvTEsLK4=',
    AUTH_CLIENT_ID: 'DahGMgZ7VgSFHneHDuedr7BN7bdWi936',
    AUTH_DOMAIN: 'https://sandbox-login.bannerflow.com',
    AUTH_AUDIENCE: 'https://bannerflow.com/resources/',
    FEATURE_FLAGS: {
        enabled: true,
        clientKey: '*:development.405451cbc85ebc1bcdc787f10b94fcaf3dae2c8ddc56e843061268c1',
        url: 'https://bf-feature-flags.azurewebsites.net/api/frontend'
    },
    NSSignalR: {
        enabled: true,
        url: 'https://sandbox-api.bannerflow.com/notification-service/hub'
    }
};
