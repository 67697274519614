export class Account {
    public id: string;
    public amAccountId: string;
    public name: string;
    public slug: string;
    public features: string[];
    public defaultLoginType: string;

    public static deserialize(json: any): Account {
        if (!json) {
            return null;
        }

        const i = new Account();
        i.id = json.id;
        i.amAccountId = json.amAccountId;
        i.name = json.name;
        i.slug = json.slug;
        i.features = json.features;
        i.defaultLoginType = json.defaultLoginType;

        return i;
    }
}
